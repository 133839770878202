<template lang="pug">
  .index-c-w
    .table-box
      MTitleI(:title="titleFormat")
      MyTable1(
        :tableData="tableData"
        :columns="columns"
        tH="70vh"
        :class="(tableClass || companyType === 2 || companyType === 4) ? 'table-class' : ''"
        @onCurrentPage="onCurrentPage"
        @rowClick="rowClick"
        :readonly="userInfo.readyOnly == 1"
      )
        <template v-slot:action="{row}">
          .cur-btn(@click.stop="onEdit(row)") 编辑
        </template>
      .back-box
        .cur-btn(@click="onBack") 返回 
    MyModal1(:modalShow="dialogVisible"  :title="editTitle" :modal="true" @onClose="onClose")          
      el-form(ref="form" :hide-required-asterisk="true" :rules="rules" :model="form" label-width="100px")
        el-form-item(v-if="companyType === 1" label="残联名称：" class="my-form-item" prop="federationName")
            Box3(className="my-box3" w="260px")
              el-input(v-model="form.federationName" placeholder="残联名称")
        el-form-item(label="联系人：" class="my-form-item" prop="contacterName")
            Box3(className="my-box3" w="260px")
              el-input(v-model="form.contacterName" placeholder="联系人")
        el-form-item(label="座机：" class="my-form-item" prop="contacterMobile")
            Box3(className="my-box3" w="260px")
                el-input(v-model="form.contacterTelephone" placeholder="座机")
        el-form-item(label="手机：" class="my-form-item" prop="contacterMobile")
            Box3(className="my-box3" w="260px")
              el-input(v-model="form.contacterMobile" placeholder="手机")
        el-form-item(label="联系地址：" class="my-form-item" prop="contacterAddress")
            Box3(className="my-box3")
              el-input(v-model="form.contacterAddress" placeholder="联系地址")
        .modal-footer
          .btn.btn1(@click="onClose") 取 消
          .btn.btn2.cur-btn(@click="onSave") 保 存
</template>

<script>
import Box3 from "../../components/Box3.vue";
import MyTable1 from "../../components/MyTable1.vue";
import MyModal1 from "../../components/MyModal1.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      tableClass: false,
      federationName: "浙江省残疾人联合会", //表头
      form: {
        //表单提交参数
        contacterName: "",
        contacterTelephone: "",
        contacterMobile: "",
        contacterAddress: "",
      },
      rules: {
        //验证规则
        federationName: [
          { required: true, message: "请输入残联名称", trigger: "blur" },
        ],
        contacterName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contacterAddress: [
          { required: true, message: "请输入联系地址", trigger: "blur" },
        ],
      },
      columns: [
        { label: "市级残联", prop: "federationName", minWidth: "180" },
        { label: "联系人", prop: "contacterName" },
        { label: "座机", prop: "contacterTelephone" },
        { label: "手机", prop: "contacterMobile" },
        { label: "联系地址", prop: "contacterAddress", minWidth: "220" },
        { label: "操作", slot: "action" },
      ],
      tableData: [],
      parentIds: [], //上一个parentId，用来返回上级使用
      companyId: 1, //4个保险公司需要传这个参数，用来查询顶级的列表，新增的参数
      editTitle: "",
    };
  },
  components: {
    Box3,
    MyTable1,
    MyModal1,
  },
  watch: {
    //市残联
    disabilityfederationList(newVal) {
      this.tableData = newVal;
    },
    //保险公司
    insurancecompanyList(newVal) {
      this.tableData = newVal;
    },
    //第三方公司
    otherThirdcompanyList(newVal) {
      this.tableData = newVal;
    },
  },

  created() {
    const {
      companyType, //组织类型 1 残联 2 保险公司 3 第三方公司
      parentId = "", //父级Id
      companyName = "", //单位公司名称
      companyId, //单位公司Id
    } = this.$route.query;
    this.companyType = Number(companyType);
    this.companyName = companyName;
    this.parentId = parentId;
    this.companyId = companyId;
    //过去table 数据
    this.getList(Number(companyType), parentId, this.companyId);
    this.columnsFormat(Number(companyType));
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      disabilityfederationList: (state) => state.app.disabilityfederationList, //市残联
      insurancecompanyList: (state) => state.app.insurancecompanyList, //保险公司
      otherThirdcompanyList: (state) => state.app.otherThirdcompanyList, //第三方保险公司
    }),
    titleFormat() {
      if (this.companyType === 4) return "浙江省残疾人联合会";
      if (this.companyType === 3) return this.companyName;
      if (this.companyType === 2) return "广润保险经纪有限公司";
      if (this.companyType === 1) return this.federationName;
    },
  },
  methods: {
    ...mapActions([
      "getPageListbyparentAction", //获取市残联list
      "saveorupdatedisabilityfederationAction", //残联更新保存
      "getInsurancecompanyListAction", //获保险公司list
      "saveorupdateinsurancecompanyAction", //保险公司修改保存
      "getOtherThirdcompanyListAction", //获取第三方公司list
      "saveOrEdirOtherThirdcompanyAction", //保存更新第三方公司信息
    ]),
    //row 点击事件
    rowClick(rowData) {
      if (this.companyName === '平安财险') return;
      if (this.companyName === '大地财险') return;
      if (this.companyType === 4) return;
      if (this.companyType === 2) return;
      if (this.companyType === 1 && this.parentIds.length === 1) return;
      if (this.parentIds.length === 2) return; //最后一层不继续下跳
      this.parentIds.push(this.parentId);
      this.parentId = rowData.federationId || rowData.companyId;
      this.getList(this.companyType, this.parentId);
      this.columnsFormat(this.companyType);
    },
    //根据不同组织调用 api 接口获取数据
    getList(companyType, parentId, companyId) {
      companyType === 1 && this.getPageListbyparentAction({ parentId });
      companyType === 2 && this.getOtherThirdcompanyListAction({ parentId });
      companyType === 3 && this.getInsurancecompanyListAction({ parentId, companyId });
      companyType === 4 && this.getPageListbyparentAction({ parentId });
    },
    //动态修改表头
    columnsFormat(companyType) {
      this.tableClass = false;
      if (companyType === 1) {
        if (this.parentIds.length == 0) {
          this.columns[0].label = "市级残联";
        } else if (this.parentIds.length == 1) {
          this.tableClass = true;
          this.columns[0].label = "区县残联";
        }
      }
      if (companyType === 2) {
        this.columns[0].label = "广润经纪";
      }
      if (companyType === 3) {
        this.columns[0].prop = "companyName";
        if (this.parentIds.length == 0) {
          this.columns[0].label = "省级保险公司";
        } else if (this.parentIds.length == 1) {
          this.columns[0].label = "市级保险公司";
        } else if (this.parentIds.length == 2) {
          this.tableClass = true;
          this.columns[0].label = "区县保险公司";
        }
        if (this.companyName === '平安财险' || this.companyName === '大地财险') {
          this.tableClass = true;
        }
      }
      if (companyType === 4) {
        this.columns[0].label = "浙江省残疾人联合会";
      }
    },
    //点击保存按钮 调用此方法 根据不同组织调用不同 api 接口
    saveInfo() {
      let companyType = this.companyType;
      if (companyType === 1 || companyType === 4) {
        return this.saveorupdatedisabilityfederationAction(this.form);
      }
      if (companyType === 2) {
        return this.saveOrEdirOtherThirdcompanyAction(this.form);
      }
      if (companyType === 3) {
        return this.saveorupdateinsurancecompanyAction(this.form);
      }
    },
    //modal 关闭
    onClose() {
      this.dialogVisible = false;
      this.clearForm();
    },
    //table 编辑按钮事件
    onEdit(item) {
      this.dialogVisible = true;
      const {
        contacterName,
        contacterTelephone,
        contacterMobile,
        contacterAddress,
        federationId,
        federationName,
        companyId,
        companyName,
      } = item;
      if (this.companyType === 1 || this.companyType === 4) {
        this.form = {
          parentFederationId: item.parentFederationId,
          contacterName,
          contacterTelephone,
          contacterMobile,
          contacterAddress,
          federationId,
          federationName,
        };
        this.editTitle = federationName;
      }
      if (this.companyType === 3 || this.companyType === 2) {
        this.form = {
          contacterName,
          contacterTelephone,
          contacterMobile,
          contacterAddress,
          companyId,
          companyName,
          companyType: "0", //公司类型0:保险总公司，1：市级保险公司，2：区县保险公司
          parentCompanyId: item.parentCompanyId,
        };
        if (this.parentIds.length == 0) {
          this.form.companyType = "0"
        } else if (this.parentIds.length == 1) {
          this.form.companyType = "1"
        } else if (this.parentIds.length == 2) {
          this.form.companyType = "2"
        }
        this.editTitle = companyName;
      }
    },
    //返回按钮事件
    onBack() {
      if (this.parentIds.length > 0) {
        this.parentId = this.parentIds.pop();
        this.columnsFormat(this.companyType);
        if (this.companyType === 3 && this.parentIds.length == 0) {
          //下面4个公司跳到最上面一层时候还要带上companyId
          this.getList(this.companyType, this.parentId, this.companyId);
        } else {
          this.getList(this.companyType, this.parentId);
        }
      } else {
        this.$router.push({ path: "/organization/company" });
      }
    },
    //清除表单
    clearForm() {
      this.form = {
        contacterName: "",
        contacterMobile: "",
        contacterAddress: "",
      };
    },
    //保存按钮事件
    onSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.form.contacterMobile && !this.form.contacterTelephone) {
            return this.$message({
                message: "座机和手机必须填一项！",
                type: "error",
            });
          }
          let res = await this.saveInfo();
          if (res.result) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.clearForm();
            if (this.parentIds.length == 0) {
                this.getList(this.companyType, this.parentId, this.companyId);
            } else {
                this.getList(this.companyType, this.parentId);
            }
          }
        }
      });
    },
    //分页触发回调
    onCurrentPage() {},
  },
};
</script>

<style lang="less" scoped>
/deep/ .my-box2 {
  padding: 30px;
  padding-bottom: 20px;
}
/deep/ .el-dialog__headerbtn {
  top: 11%;
}
.index-c-w {
  .df;
  .dfc;
}
.back-box {
  .df;
  .jcc;
  margin-top: 20px;
}
.table-box,
.filter-box {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
}
.my-form-item {
  /deep/ .el-form-item__label {
    color: #5a86d3;
    font-size: 14px;
  }
}
.modal-footer {
  .df;
  .dfac;
  .jcc;
  padding: 20px 0;
  .btn {
    margin: 0 20px;
    cursor: pointer;
  }
  .btn1 {
    width: 80px;
    height: 30px;
    border: 1px solid #318ef0;
    border-radius: 2px;
    color: #318ef0;
    line-height: 30px;
    text-align: center;
  }
  .btn1:hover {
    color: #318ef0;
    border: 0.0625rem solid #318ef0;
    background: rgba(65, 105, 225, 0.3);
  }
}
.filter-box {
  height: 72px;
  margin-bottom: 10px;
  .df;
  .dfac;
  .filter-citem {
    .df;
    .dfac;
    color: #305baa;
    height: 32px;
    & > span {
      text-align: right;
    }
    .cur-btn {
      margin-left: 50px;
    }
    .label-span1 {
      width: 60px;
    }
    .label-span2 {
      width: 100px;
    }
    .label-span3 {
      width: 120px;
    }
    /deep/ .ar {
      width: 10px;
      height: 10px;
    }
    /deep/ .box-w {
      height: 100%;
      width: 152px;
    }
  }
}

.table-box {
  .m-title-box {
    padding-bottom: 20px;
  }
  padding: 20px;
  color: #fff;
  overflow: hidden;
  height: 84vh;
}

// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
  position: absolute;
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(28, 42, 117, 0.2);
  border-radius: 3px;
  position: absolute;
}

/deep/ .el-table tr td:nth-child(1) .cell {
  text-decoration: underline;
}
/deep/ .companyType2 .el-table tr td:nth-child(1) .cell {
  text-decoration: none;
}
/deep/ .table-class .el-table tr td:nth-child(1) .cell {
  text-decoration: none;
}
/deep/ .el-table__row {
  cursor: pointer;
}
</style>
